import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import setDay from 'date-fns/setDay'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
// link to formats https://date-fns.org/v2.13.0/docs/format

/**
 *
 * @type {string} 5:48 PM MAY 7, 2020
 */
const DATETIME_FORMAT_DEFAULT = 'p PP'
/**
 *
 * @type {string} MAY 7, 2020
 */
const DATE_FORMAT_DEFAULT = 'PP'
/**
 *
 * @type {string} 5:48 PM
 */
const TIME_FORMAT_DEFAULT = 'p'

/**
 * returns a string like 5:48 PM MAY 7, 2020
 * @param date String|Date
 * @param dateTimeFormat String
 * @returns {string | *}
 */
const dateTimeString = (date, dateTimeFormat = DATETIME_FORMAT_DEFAULT) => {
  if (typeof date === 'string' && date !== '') {
    return format(parseISO(date), dateTimeFormat)
  } else if (typeof date === 'object') {
    try {
      return format(date, dateTimeFormat)
    } catch {
      return format(new Date(), dateTimeFormat)
    }
  }
  return format(new Date(), dateTimeFormat)
}

/**
 *
 * @param startDateTime
 * @param endDateTime
 * @param delimiter
 * @param dateTimeFormat
 * @returns {string}
 */
const dateTimeRange = (startDateTime, endDateTime, delimiter = '-', dateTimeFormat = DATETIME_FORMAT_DEFAULT) => {
  return dateTimeString(startDateTime, dateTimeFormat) + delimiter + dateTimeString(endDateTime, dateTimeFormat)
}

/**
 * returns a string like MAY 7, 2020
 * @param date Date|String
 * @param dateFormat String
 * @returns {string | *}
 */
const dateString = (date, dateFormat = DATE_FORMAT_DEFAULT) => {
  if (typeof date === 'string' && date !== '') {
    return format(parseISO(date), dateFormat)
  } else if (typeof date === 'object') {
    try {
      return format(date, dateFormat)
    } catch {
      return format(new Date(), dateFormat)
    }
  }
  return format(new Date(), dateFormat)
}

const dateRange = (startDate, endDate, delimiter = '-', dateFomat = DATE_FORMAT_DEFAULT) => {
  return timeString(startDate, dateFomat) + delimiter + timeString(endDate, dateFomat)
}

/**
 * returns a string like 5:48 PM
 * @param time string|date
 * @param timeFormat String
 * @returns {string | *}
 */
const timeString = (time, timeFormat = TIME_FORMAT_DEFAULT) => {
  if (typeof time === 'string' && time !== '') {
    try {
      return format(parseISO(time), timeFormat)
    } catch (e) {
      console.log('timeString', time, timeFormat)
      return time
    }
  } else if (typeof time === 'object') {
    try {
      return format(time, timeFormat)
    } catch {
      return format(new Date(), timeFormat)
    }
  }
  return format(new Date(), timeFormat)
}

const timeRange = (startTime, endTime, delimiter = '-', timeFormat = TIME_FORMAT_DEFAULT) => {
  return timeString(startTime, timeFormat) + delimiter + timeString(endTime, timeFormat)
}

/**
 * Formats a string or date to a humanized time.
 * Example: 12:00 a -> midnight 12:00 pm -> noon  1:30 pm -> 1:30 pm
 *
 * @param {String|Date} time or date
 * @param {String} timeFormat
 * @return {String}
 *
 */
const humanizeTime = (time, timeFormat = TIME_FORMAT_DEFAULT) => {
  if (timeFormat || typeof timeFormat === 'undefined') {
    timeFormat = TIME_FORMAT_DEFAULT
  }
  if (typeof time === 'string') {
    time = parseISO(time)
  }
  if (typeof time === 'undefined') {
    return ''
  }
  if (time.getMinutes() === 0) {
    if (time.getHours() === 0) {
      return 'midnight'
    } else if (time.getHours() === 12) {
      return 'noon'
    }
  }
  return format(time, timeFormat)
}

const humanizeRange = (startTime, endTime, delimiter = '-', timeFormat = TIME_FORMAT_DEFAULT) => {
  return humanizeTime(startTime, timeFormat) + delimiter + humanizeTime(endTime, timeFormat)
}

/**
 *
 * @param dayOfWeek
 * @param isLong
 * @returns {string}
 */
const dayOfWeek = (dayOfWeek, isLong) => {
  const day = setDay(new Date(), dayOfWeek)
  if (isLong || typeof isLong === 'undefined') {
    return format(day, 'EEEE')
  } else {
    return format(day, 'EEE')
  }
}

const dateAgo = isoDate => {
  const date = parseISO(isoDate)
  return formatDistanceToNow(date, { addSuffix: true })
}

/**
 *
 * @param year
 * @param isLong
 * @returns {string}
 */
const schoolYear = (year, isLong) => {
  if (isLong || typeof isLong === 'undefined') {
    return year + '-' + (+year + 1)
  } else {
    const shortYear = year.slice(-2)
    return shortYear + '-' + (+shortYear + 1)
  }
}

export {
  dateTimeString, dateTimeRange,
  dateString, dateRange,
  timeString, timeRange,
  dateAgo,
  humanizeTime,
  humanizeRange,
  dayOfWeek, schoolYear,
  DATE_FORMAT_DEFAULT, DATETIME_FORMAT_DEFAULT, TIME_FORMAT_DEFAULT
}
