<template>
  <div
    class="list-group-item list-group-item-warning"
    v-if="registration.has_lunch_gap > 0"
  >
    <p class="lead">
      <strong>Missing Lunch Supervision</strong>&nbsp;
      <small
        >You need to check that you have lunch supervision for students that are
        enrolled in classes before and after lunch.</small
      >
    </p>
    <dl class="row">
      <template v-for="(session, session_id) in registration.lunch_gaps">
        <dt class="col-sm-3" :key="'session_dt_' + session_id">
          <span v-html="session.name"></span>
        </dt>
        <dd class="col-sm-9" :key="'session_dd_' + session_id">
          <dl class="row" v-for="student in session.students" :key="student.id">
            <dt class="col-sm-4">{{ student.name }}</dt>
            <dd class="col-sm-8">
              May be short {{ plural('lunch selection',missingCount(student), true) }}
            </dd>
          </dl>
        </dd>
      </template>
    </dl>
  </div>
</template>

<script>
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import { plural } from '@/utilities/stringFormatters'

export default {
  name: 'LunchGaps',
  props: ['registration'],
  methods: {
    plural,
    missingCount (student) {
      if (isNil(student.lunches) || isEmpty(student.lunches)) {
        return student.days
      } else {
        return student.days - student.lunches.length
      }
    }
  }
}
</script>

<style scoped></style>
