import { MANAGER_API, API, urlBuilder } from '../utilities/http-common'

const getRegistrations = (params) => {
  return MANAGER_API.get(`programs/${params.program}/registrations/api-index`, {
    params: params
  }).then(response => {
    if (response.status === 200) {
      const data = response.data
      return data.registrations
    } else {
      const error = new Error(response.statusText)
      error.code = response.status
      throw error
    }
  })
}

const getCurrentRegistrations = (year) => {
  const params = {
    year: year
  }
  return API.get('registrations/current', { params: params })
    .then(response => {
      if (response.status === 200) {
        const data = response.data
        return data.registrations
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        throw error
      }
    })
}

const hasRegistration = (year, program) => {
  const params = {
    year: year,
    program: program.id
  }
  return API.get('registrations/current', { params: params })
    .then(response => {
      if (response.status === 200) {
        const data = response.data
        return data.registrations
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        throw error
      }
    })
}

const newRegistration = (year, program, studentIds) => {
  const data = {
    year: year,
    program_id: program.id,
    student_id: studentIds
  }
  return API.post(`programs/${program.slug}/registrations/create-new`, data)
    .then(response => {
      if (response.status === 200) {
        const data = response.data
        return data
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        throw error
      }
    })
}

const addStudentToRegistration = (student_id, registration_id, member_id, program, forceLink = false) => {
  var returnTo = window.location.pathname
  const data = {
    registration_id: registration_id,
    student_id: student_id,
    member_id: member_id,
    force_link: forceLink,
    return_to: returnTo
  }
  return API.put(`programs/${program.slug}/registrations/add-student`, data)
    .then(response => {
      if (response.status === 200) {
        const data = response.data
        return data
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        throw error
      }
    })
}

export { getRegistrations, getCurrentRegistrations, hasRegistration, newRegistration, addStudentToRegistration }
