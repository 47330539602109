<template>
  <div class="card border-info">
    <h3 class="card-header bg-info text-light">How to get started</h3>
    <div class="card-body bg-info-light">
      <p class="lead">
        In order to create a registration and enroll in
        <span v-html="program.name" /> you have to login or become a member.
      </p>
      <p class="card-subtitle">
        If you do not remember if you have an account please use the
        <a href="/?register=0" class="btn btn-primary btn-sm">
          Reset Password
        </a>
        where you can reset your password with a username or email, or call
        Community Schools,
        <a href="tel:(520)209-7551" class="btn btn-outline-info btn-sm"
          ><cs-icon icon="phone" /> (520)209-7551</a
        >
      </p>
    </div>
    <div class="card-footer">
      <div class="row d-flex justify-content-around">
        <div class="col">
          <a href="/?register=0" class="btn btn-primary btn-block btn-lg">
            Login
          </a>
        </div>
        <div class="col">
          <a href="/?register=0" class="btn btn-primary btn-block btn-lg">
            Reset Password
          </a>
        </div>
        <div class="col">
          <a href="/?register=1" class="btn btn-info btn-block btn-lg">
            Become a Member
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoggedOut',
  components: {},
  data () {
    return {
      register: false
    }
  },
  props: {
    program: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped></style>
