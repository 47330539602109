<template>
  <div>
    <div class="custom-control custom-checkbox">
      <input
        :id="checkboxId"
        v-on:change="setNapper($event)"
        v-model="is_napper"
        class="custom-control-input"
        type="checkbox"
        :disabled="disabled"
        :class="{ disabled: disabled }"
      />
      <label class="custom-control-label" :for="checkboxId">
        <slot name="default">
          Will nap
        </slot>
      </label>
      <small v-if="help !== ''" class="form-text text-muted">
        {{ help }}
      </small>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { SET_STUDENT_IS_NAPPER } from '@/constants/mutation-types'

export default {
  name: 'IsNapper',
  props: {
    student: {
      required: true,
      type: Object
    },
    help: {
      required: false,
      type: [String],
      default: ''
    }
  },
  created () {
    this.is_napper = this.student.is_napper
  },
  data: function () {
    return {
      is_napper: false,
      disabled: false
    }
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration'
    }),
    checkboxId: function () {
      return 'checkbox-is-napper-' + this.student.id
    }
  },
  methods: {
    ...mapActions({
      setIsNapper: SET_STUDENT_IS_NAPPER
    }),
    setNapper: function () {
      this.disabled = true
      const notify = this.$notify
      this.setIsNapper({
        student_id: this.student.id,
        is_napper: this.is_napper,
        registration_id: this.registration.id,
        registration_student_id: this.student.registration_student.id
      })
        .then(result => {
          notify({
            group: 'enrollment',
            type: 'success',
            title: 'Set nap state',
            text: result.message,
            duration: 2000,
            speed: 1000
          })
          this.disabled = false
        })
        .catch(e => {
          notify({
            group: 'enrollment',
            type: 'error',
            title: 'Set nap state',
            text: e.message,
            duration: 2000,
            speed: 1000
          })
          this.disabled = false
        })
    }
  }
}
</script>

<style scoped></style>
