<template>
  <div class="card border-warning" :key="year">
    <div
      class="card-header bg-warning"
    >
      <h4>
        Start a registration for <span v-html="program.name" /> for
        {{ schoolYear(year) }}
        <cs-disclose :value="expanded"
                     class="float-right card-toggle"
                     @handle-click="$emit('click-year', year)">
          <template v-slot:undisclosed>Click to open</template>
          <template v-slot:disclosed>Click to close</template>
        </cs-disclose>
      </h4>
    </div>
    <transition>
      <div v-if="expanded" class="card-body">
        <div class="alert alert-warning">
          <p v-if="program.signup === ''" class="lead">
            In order to enroll in any programs in
            <span v-html="program.name" /> you need to start a registration.
            This will include adding the students that are planning on
            registering for classes in this program as well as completing any
            needed Information Cards needed for that program. If you have filled
            in <strong>Information Cards</strong> for those students for other
            programs you may only need to update or add any information needed
            for <span v-html="program.name" />. You will be able to add more
            students later if needed.
          </p>
          <span v-else class="lead" v-html="program.signup"></span>
        </div>
      </div>
    </transition>
    <transition name="fade" v-if="expanded && $wait.is('loading-students')">
      <div class="list-group list-group-flush">
        <div class="list-group-item list-group-item-info">
          <cs-loading theme="info" sm>
            Loading Students
          </cs-loading>
        </div>
      </div>
    </transition>
    <transition>
      <div
        v-if="expanded && eligible.length > 0"
        class="list-group list-group-flush"
      >
        <div
          v-for="student in eligible"
          :key="student.id"
          class="list-group-item"
        >
          <button
            v-on:click.prevent="newWithStudent(student, $event)"
            class="btn-primary btn-block btn-truncate-text btn"
          >
            Start a registration for {{ student.first }} ({{ student.grade }}-{{
              displayAbbreviation(student.school)
            }}) in <span v-html="program.name" />
            {{ schoolYear(year) }}
          </button>
        </div>
      </div>
    </transition>
    <transition>
      <div
        v-if="expanded && ineligible.length > 0"
        class="list-group list-group-flush"
      >
        <div class="list-group-item list-group-item-warning">
          The following students may not be eligible for
          <span v-html="program.name" />. You will be able to update/ or set the
          grade and school for this year as part of the registration process.
        </div>
        <div
          v-for="student in ineligible"
          :key="student.id"
          class="list-group-item"
        >
          {{ student.name }}
          <small v-if="student.grade && student.school">
            ({{ student.grade }} {{ displayAbbreviation(student.school) }})
          </small>
          <small v-else>({{ student.grade_deprecated }} {{ student.school_deprecated }}) <span class="text-muted">Update with registration</span></small>
          <span class="btn-group float-right">
            <a
              v-on:click="newWithStudent(student, $event)"
              class="btn-primary btn-truncate-text btn"
            >
              Start registration for {{ student.first }}
              {{ schoolYear(year) }}
            </a>
            <a v-on:click="viewStudent(student, $event)" class="btn btn-info">
              <font-awesome-icon icon="eye" />View
            </a>
          </span>
        </div>
      </div>
    </transition>
    <transition>
      <div v-if="expanded" class="card-footer">
        <button
          class="btn btn-success btn-block btn-truncate-text"
          v-on:click.prevent="addNewStudent"
        >
          Add a new student for registration in <span v-html="program.name" />
          {{ schoolYear(year) }}
        </button>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { API, urlBuilder } from '@/utilities/http-common'
import isEmpty from 'lodash/isEmpty'

import { schoolYear } from '@/utilities/dateFormatters'
import { displayAbbreviation, disableFromEvent } from '@/utilities/cs-utilities'

export default {
  name: 'create-registration',
  props: {
    expanded: {
      type: Boolean,
      required: true
    },
    program: {
      type: Object,
      required: true
    },
    year: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      grade: {},
      gradeName: {},
      school: {},
      schoolName: {},
      eligible: [],
      ineligible: [],
      studentId: ''
    }
  },
  created () {
    this.$wait.start('loading-students')
    API.get(`/programs/${this.program.id}/students.json?year=${this.year}`)
      .then(response => {
        this.$wait.end('loading-students')
        const data = response.data
        if (!isEmpty(data.eligible)) {
          this.eligible = data.eligible
        }
        if (!isEmpty(data.ineligible)) {
          this.ineligible = data.ineligible
        }
        this.$notify({
          group: 'enrollment',
          type: 'success',
          title: 'Students loaded',
          duration: 2000,
          speed: 1000
        })
      })
      .catch(error => {
        this.$wait.end('loading-students')
        this.$notify({
          group: 'enrollment',
          type: 'error',
          title: 'Problem loading students',
          text: error.message,
          duration: 2000,
          speed: 1000
        })
      })
  },
  computed: {
    ...mapGetters({
      currentYear: 'year',
      registrationMember: 'registrationMember',
      loggedInMember: 'member',
      loggedIn: 'loggedIn',
      schools: 'schools',
      grades: 'grades',
      isManager: 'isProgramManager'
    }),
    member: function () {
      if (isEmpty(this.registrationMember)) {
        return this.loggedInMember
      } else {
        return this.registrationMember
      }
    }
  },

  methods: {
    displayAbbreviation,
    schoolYear,
    edit (student) {
      let matches = []
      let needle = student.grade // what to look for

      this.grades.forEach(function (level) {
        matches = matches.concat(
          level.gradeGroup.filter(function (grade) {
            return grade.value === needle
          })
        )
      })

      this.grade = matches[0]
      matches = []
      needle = student.school // what to look for

      this.schools.forEach(function (level) {
        matches = matches.concat(
          level.schoolGroup.filter(function (school) {
            return school.value === needle
          })
        )
      })
      this.school = matches[0]
      this.studentId = student.id
    },
    addNewStudent (event) {
      disableFromEvent(event)
      const returnTo = window.location.pathname
      const params = {
        member_id: this.member.id,
        program: this.program.slug,
        year: this.year,
        next: 'new_registration',
        return_to: returnTo
      }
      window.location = urlBuilder('students/add', params)
    },
    newWithStudent: function (student, event) {
      disableFromEvent(event)
      const returnTo = window.location.pathname
      window.location = urlBuilder('/registrations/new', {
        program: this.program.slug,
        year: this.year,
        student_id: student.id,
        member_id: this.member.id,
        return_to: returnTo
      })
    },
    viewStudent (student, event) {
      disableFromEvent(event)
      this.$nextTick(() => {
        const returnTo = window.location.pathname
        window.location = urlBuilder('/students/view', {
          student_id: student.id,
          return_to: returnTo
        })
      })
    },
    saveStudent (student, event) {
      event.target.disabled = true
      const body = {
        student_id: student.id,
        grade: this.grade.value,
        school: this.school.value,
        programSlug: this.program.slug
      }
      const url = 'students/edit/' + student.id

      API.patch(url, body)
        .then(response => {
          const data = response.data
          event.target.disabled = false

          if (typeof data !== 'undefined') {
            const result = data.result
            if (
              typeof result.isError !== 'undefined' &&
              result.isError === false
            ) {
              const member = data.member
              this.studentId = ''
              const updatedStudent = data.student
              this.updateMember(member)

              this.$notify({
                group: 'enrollment',
                type: 'success',
                title: updatedStudent.name + ' updated',
                text: updatedStudent.name + ' updated with your changes.',
                duration: 2000,
                speed: 1000
              })
            } else if (result.isError === true) {
              return alert(result.message)
            }
          }
        })
        .catch(e => {
          event.target.disabled = false
          this.$notify({
            group: 'enrollment',
            type: 'error',
            title: student.name + ' not updated',
            text: student.name + ' was not able to be updated: ' + e.message,
            duration: 2000,
            speed: 1000
          })
        })
    }
  }
}
</script>
