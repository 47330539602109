<template>
  <div
    class="list-group-item list-group-item-warning"
    v-if="registration.hasGaps"
  >
    <p class="lead">Scheduling Gaps</p>
    <dl class="row">
      <template v-for="(session, session_id) in registration.gaps">
        <dt class="col-sm-3" :key="'session_dt_' + session_id">
          <span v-html="session.name"></span>
        </dt>
        <dd class="col-sm-9" :key="'session_dd_' + session_id">
          <dl
            class="row"
            v-for="student in session.students"
            :key="'student_' + student.id"
          >
            <dt class="col-sm-4">{{ student.name }}</dt>
            <dd class="col-sm-8">
              <span v-for="gap in student.gaps" :key="gap"
                >[{{ timeRange(gap.start, timeStringgap.end) }}]&nbsp;</span
              >
            </dd>
          </dl>
        </dd>
      </template>
    </dl>
  </div>
</template>

<script>
import { timeRange } from '@/utilities/dateFormatters'
export default {
  name: 'Gaps',
  props: ['registration'],
  methods: {
    timeRange
  }
}
</script>

<style scoped></style>
