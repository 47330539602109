<template>
  <div id="create-registration" v-if="years.length > 0">
    <div v-if="loading" class="card bg-info-light">
      <h4 class="card-header">
        <cs-loading theme="info">
          Checking other active years for registration
        </cs-loading>
      </h4>
    </div>
    <template v-if="false">
      <template v-for="year in years">
        <create-registration
            :year="year"
            :expanded="expandedYear === year"
            :program="program"
            @click-year="clickYear"
            :key="year"
        />
      </template>
    </template>
    <template v-else>
      <new-registration v-for="newYear in openYears"
                        :year="newYear"
                        :initially-expanded="year === newYear"
                        :key="newYear"
      />
    </template>
  </div>
  <div v-else-if="registration === null" class="alert alert-info">
    <h4>
      There are currently no registration periods open for <span v-html="program.name"/>
    </h4>
    <p class="lead">
      Please check with the {{ program.admin_name }} for more information
      <a v-bind:href="'mailto:' + program.admin_email" class="btn btn-info">
        <font-awesome-icon icon="envelope"/>
        {{ program.admin_name }}</a
      >
      {{ phone(program.phone) }} for more information
    </p>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { InitialState } from '@/utilities/cs-utilities'
import { UPDATE_MEMBER } from '@/constants/mutation-types'
import { API, urlBuilder } from '@/utilities/http-common'
import isEmpty from 'lodash/isEmpty'
import { phone } from '@/utilities/stringFormatters'
import CreateRegistration from '@/components/registrations/CreateRegistration'
import { isDateBetween, schoolYearFromDate } from '@/utilities/utilities'
import { formatISO } from 'date-fns'
import addYears from 'date-fns/addYears'

export default {
  name: 'CreateRegistrations',
  components: {
    NewRegistration: () => import(/* webpackChunkName: "NewRegistration" */ '@/components/registrations/NewRegistration'),
    CreateRegistration
  },
  props: {
    registration: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      expandedYear: 0,
      studentId: 0,
      school: '',
      grade: [],
      loading: false,
      hasThisYear: false,
      hasNextYear: false,
      addStudentsVisible: false,
      currentYear: this.year,
      openYears: []
    }
  },
  created () {
    if (this.currentOpen) {
      this.currentExpanded = true
    } else {
      this.nextExpanded = true
    }
    if (typeof InitialState().CURRENT_YEAR !== 'undefined') {
      this.currentYear = InitialState().CURRENT_YEAR
    } else {
      this.currentYear = this.year
    }
  },
  mounted () {
    this.expandedYear = 0
    if (this.isOpen(this.year + 1)) {
      this.expandedYear = this.year + 1
    }
    if (this.isOpen(this.year)) {
      this.expandedYear = this.year
    }
    if (
      typeof this.registration === 'undefined' ||
        this.registration === null
    ) {
      if (this.year !== this.nextYear) {
        // check if next year has a registration
        const years = this.years
        if (years.length === 1) {
          const year = years[0]
          if (year === this.nextYear) {
            this.loading = true
            API.get(
              'programs/' +
                this.program.slug +
                '/registrations/has-registration',
              {
                params: {
                  member_id: this.member.id,
                  year: this.nextYear
                }
              }
            )
              .then(result => {
                const data = result.data
                if (data.hasRegistration) {
                  const url = urlBuilder('/' + this.program.slug, {
                    year: this.nextYear
                  })
                  window.location = url
                  this.loading = false
                } else {
                  this.loading = false
                }
              })
              .catch(() => {
                this.loading = false
              })
          }
        }
      }
    } else {
      this.hasNextYear = true
      this.hasThisYear = true
      this.checkForRegistrations()
    }
    this.checkYearsForRegistrations()
  },
  computed: {
    ...mapGetters({
      year: 'year',
      program: 'program',
      registrationMember: 'registrationMember',
      loggedInMember: 'member',
      loggedIn: 'loggedIn',
      schools: 'schools',
      grades: 'grades',
      isManager: 'isProgramManager'
    }),
    member: function () {
      if (isEmpty(this.registrationMember)) {
        return this.loggedInMember
      } else {
        return this.registrationMember
      }
    },
    years: function () {
      const years = []
      let now = new Date()
      if (isDateBetween(this.program.registration_start, this.program.registration_end)) {
        years.push(schoolYearFromDate(formatISO(now)))
      }
      if (isDateBetween(this.program.next_year_registration_start, this.program.next_year_registration_end)) {
        now = addYears(now, 1)
        years.push(schoolYearFromDate(now))
      }
      return years
    },
    nextYear: function () {
      return this.currentYear + 1
    },
    eligible: function () {
      if (!isEmpty(this.member.eligible)) {
        return this.member.eligible
      } else {
        return []
      }
    },
    ineligible: function () {
      if (!isEmpty(this.member.ineligible)) {
        return this.member.ineligible
      } else {
        return []
      }
    },
    schoolName: function () {
      if (typeof this.school.name !== 'undefined') {
        return this.school.name
      }
      return ''
    },
    gradeName: function () {
      if (typeof this.grade.name !== 'undefined') {
        return this.grade.name
      }
      return ''
    }
  },
  methods: {
    ...mapMutations({
      updateMember: UPDATE_MEMBER
    }),
    phone,
    checkYearsForRegistrations () {
      if (this.years.length > 0) {
        this.years.forEach(year => {
          API.get(
            'programs/' + this.program.slug + '/registrations/has-registration',
            {
              params: {
                member_id: this.member.id,
                year: year
              }
            }
          )
            .then(result => {
              const data = result.data
              console.log('checkYearsForRegistrations, year', year, data)
              if (!data.hasRegistration) {
                this.openYears.push(year)
              }
            })
            .catch((e) => {
              console.log('checkYearsForRegistrations, year', year, e)
            })
        })
      }
    },
    checkForRegistrations: function () {
      if (
        this.isOpen(this.currentYear) &&
            this.registration.year !== this.currentYear
      ) {
        this.loading = true
        const vm = this
        API.get(
          'programs/' + this.program.slug + '/registrations/has-registration',
          {
            params: {
              member_id: this.member.id,
              year: this.currentYear
            }
          }
        )
          .then(result => {
            const data = result.data
            if (data.hasRegistration) {
              vm.hasThisYear = true
              this.loading = false
            } else {
              this.loading = false
              vm.hasThisYear = false
              this.$nextTick(function () {
                this.$scrollTo('#create-registration')
              })
            }
          })
          .catch(() => {
            this.loading = false
            vm.hasThisYear = false
            this.$nextTick(function () {
              this.$scrollTo('#create-registration')
            })
          })
      }
      if (
        this.isOpen(this.nextYear) &&
            this.registration.year !== this.nextYear
      ) {
        this.loading = true
        const vm = this
        API.get(
          'programs/' + this.program.slug + '/registrations/has-registration',
          {
            params: {
              member_id: this.member.id,
              year: this.nextYear
            }
          }
        )
          .then(result => {
            const data = result.data
            if (data.hasRegistration) {
              vm.hasNextYear = true
              this.loading = false
            } else {
              this.loading = false
              vm.hasNextYear = false
              this.$nextTick(function () {
                this.$scrollTo('#create-registration')
              })
            }
          })
          .catch(() => {
            this.loading = false
            vm.hasNextYear = false
            this.$nextTick(function () {
              this.$scrollTo('#create-registration')
            })
          })
      }
    },
    clickYear (year) {
      if (this.expandedYear === year) {
        this.expandedYear = 0
      } else {
        this.expandedYear = year
      }
    },
    isOpen (year) {
      if (year === this.currentYear) {
        return isDateBetween(this.program.registration_start, this.program.registration_end)
      } else {
        return isDateBetween(this.program.next_year_registration_start, this.program.next_year_registration_end)
      }
    }
  }
}
</script>
