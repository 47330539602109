<template>
  <div>
    <label class="typo__label">Select T-Shirt Size (optional)</label>
    <multiselect
        :value="size"
        :disabled="disabled"
        deselect-label="Press enter to remove"
        track-by="id"
        label="size"
        placeholder="Select t-shirt size"
        :options="sizes"
        :searchable="false"
        :allow-empty="true"
        @input="selectSize"
    >
    </multiselect>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { SET_STUDENT_T_SHIRT_SIZE } from '@/constants/mutation-types'

export default {
  name: 'TShirt',
  props: {
    student: {
      required: true,
      type: Object
    }
  },
  created: function () {
    if (
      isEmpty(this.student.registration_student) ||
        isEmpty(this.student.registration_student.t_shirt_size)
    ) {
      this.size = null
    }
    const sizeId = this.student.registration_student.t_shirt_size
    this.size = this.sizes.find(size => {
      return size.id === sizeId
    })
  },
  data: function () {
    return {
      size: null,
      disabled: false
    }
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      students: 'students',
      sizes: 't_shirt_sizes'
    })
  },
  methods: {
    ...mapActions({
      setSize: SET_STUDENT_T_SHIRT_SIZE
    }),
    selectSize: function (size) {
      this.disabled = true
      const notify = this.$notify
      let sizeId = null
      if (!isNil(size) && !isNil(size.id)) {
        sizeId = size.id
      }
      this.setSize({
        student_id: this.student.id,
        size: sizeId,
        registration_id: this.registration.id,
        registration_student_id: this.student.registration_student.id
      })
        .then(result => {
          notify({
            group: 'enrollment',
            type: 'success',
            title: 'Set Size',
            text: result.message,
            duration: 2000,
            speed: 1000
          })
          this.disabled = false
          this.$emit('selected', size.size, this.student)
        })
        .catch(e => {
          notify({
            group: 'enrollment',
            type: 'error',
            title: 'Set Size',
            text: e.message,
            duration: 2000,
            speed: 1000
          })
          this.disabled = false
        })
      this.size = size
    }
  }
}
</script>

<style scoped></style>
