<template>
    <div id="registration" v-if="registration != null && loggedIn">
        <transition name="fade" mode="out-in">
            <div v-if="status === 'loading'">
                <div class="alert alert-info">
                    <cs-loading theme="info" sm>
                        Checking for active registration
                    </cs-loading>
                </div>
            </div>
            <div v-else-if="status === 'error'">
                <div class="alert alert-danger">
                    <h2>
                        Problem Loading Registration
                    </h2>
                </div>
            </div>
            <div v-else-if="status === 'empty'">
                <create-registrations/>
            </div>
            <div
                    v-else
                    class="card w-100"
                    key="loaded"
                    :class="{ 'border-danger': balance > 0, 'border-warning': closed }"
            >
                <create-registrations
                        v-if="registration"
                        :registration="registration"
                />
                <h4
                        class="card-header"
                        :class="{
            'bg-danger-light': balance > 0,
            'bg-warning-light': closed
          }"
                >
                    <strong v-if="closed"
                    >[Closed {{ dateTimeString(closedDate) }}]</strong
                    >
                    #: {{ registration.id }} ({{ schoolYear(registration.year) }})
                    <small>{{ dateString(registration.date) }}</small>
                    <span class="float-right">
            Balance:
            <span>{{ currency(balance - pendingAmountDue) }}</span>
            <template v-if="pendingAmountDue > 0">
              <span class="text-success">{{
                  currency(pendingAmountDue)
                  }}</span></template
            >&nbsp;
            <button
                    v-if="balance > 0"
                    @click="openRegistration"
                    class="btn btn-danger"
                    v-tooltip.top="{ content: 'Open Registration/Account' }"
            >
              <font-awesome-icon icon="credit-card-front"/>
              Pay {{ currency(balance) }}
            </button>
            <button
                    v-else-if="balance < 0"
                    @click="openRegistration"
                    class="btn btn-success"
                    v-tooltip.top="{ content: 'Open Registration/Account' }"
            >
              <font-awesome-icon icon="file-invoice-dollar"/>
              Credit {{ currency(balance) }}
            </button>
            <button
                    v-else
                    @click="openRegistration"
                    class="btn btn-primary"
                    v-tooltip.top="{ content: 'Open Registration/Account' }"
            >
              <font-awesome-icon icon="file-invoice-dollar"/>
              Registration {{ currency(balance) }}
            </button>
          </span>
                </h4>
                <div v-if="status === 'updating'">
                    <div class="card-body bg-info-light" key="updating">
            <span class="lead">
              <span class="spinner-grow text-primary text-center" role="status">
                <span class="sr-only">Updating...</span>
              </span>
              Updating Registration...
            </span>
                    </div>
                </div>
                <div class="list-group list-group-flush">
                    <div v-if="closed" class="list-group-item list-group-item-danger">
                        <h3>This registration has been closed</h3>
                        <p class="lead">
                            Only Community Schools can add or change enrollments on this
                            registration.
                        </p>
                    </div>
                    <div v-if="parseInt(program.current_year) !== parseInt(registration.year)"
                         class="list-group-item list-group-item-warning">
                        <h3>This registration is not for current year</h3>
                        <p class="lead">
                            Please be aware that this registration is not for the current
                            school year {{ schoolYear(program.current_year) }} it is for the
                            {{ schoolYear(registration.year) }}. Please confirm you are
                            intending to use this registration before adding days or classes.
                        </p>
                    </div>
                    <div class="list-group-item" v-if="hasStudents()">
                        <div class="row">
                            <div
                                    class="col"
                                    v-for="student in registration.students"
                                    :key="student.id"
                            >
                                {{ student.name }}
                                <small>({{ student.grade }} {{ displayAbbreviation(student.school) }})</small>
                                <span v-if="validatedStudent(student)" class="text-success">
                  <cs-icon icon="validated"></cs-icon>
                </span>
                                <div v-if="program.track_napper" class="form-check">
                                    <napper :student="student"/>
                                </div>
                                <div v-if="program.track_tshirt">
                                    <t-shirt :student="student"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <gaps :registration="registration"></gaps>
                    <lunch-gaps :registration="registration"></lunch-gaps>
                    <template
                            v-if="addStudentsVisible && typeof filteredEligible !== 'undefined' && filteredEligible.length > 0"
                    >
                        <div class="list-group-item list-group-item-info">
                            The following students are eligible for <span v-html="program.name"/>.
                        </div>
                        <div
                                class="list-group-item"
                                v-for="student in filteredEligible"
                                :key="student.id"
                        >
                            {{ student.name }}
                            <small>({{ student.grade }} {{ displayAbbreviation(student.school) }})</small>
                            <span class="btn-group float-right"
                            ><button
                                    v-on:click.prevent="viewStudent(student, $event)"
                                    class="btn btn-info"
                            >
                  <font-awesome-icon icon="eye"/> View</button
                            ><cs-button
                                    @click="addStudent(student)"
                                    type="primary"
                                    :loading="$wait.waiting('students.add')"
                                    icon="link"
                            >Add to registration
                </cs-button></span
                            >
                        </div>
                    </template>

                    <template
                            v-if="
              addStudentsVisible && typeof filteredIneligible !== 'undefined' && filteredIneligible.length > 0
            "
                    >
                        <div class="list-group-item list-group-item-warning">
                            The following students may not be eligible for <span v-html="program.name"/>.
                            If you see an incorrect grade and/or school, you will be able to update in the information
                            card later.
                        </div>
                        <div
                                class="list-group-item"
                                v-for="student in filteredIneligible"
                                :key="student.id"
                        >
                            <template v-if="student.id !== studentId">
                                {{ student.name }}
                                <small>({{ student.grade }} {{ displayAbbreviation(student.school) }})</small>
                                <span class="btn-group float-right">
                  <button
                          v-on:click.prevent="viewStudent(student, $event)"
                          class="btn btn-info"
                  >
                  <font-awesome-icon icon="eye"/> View
                  </button>
                  <button
                          v-on:click.prevent="addStudent(student, $event)"
                          class="btn btn-secondary"
                  >
                  <font-awesome-icon icon="link"/> Add to registration
                </button>
                </span>
                            </template>
                            <template v-else>
                                <div class="row">
                                    <div class="col">
                                        {{ student.name }}
                                        <small>({{ gradeName }} {{ schoolName }})</small>
                                    </div>

                                    <div class="col">
                                        <multiselect
                                                v-model="grade"
                                                :options="grades"
                                                :multiple="false"
                                                group-values="gradeGroup"
                                                group-label="level"
                                                :group-select="false"
                                                placeholder="Type to search"
                                                track-by="value"
                                                label="name"
                                        >
                      <span slot="noResult"
                      >Oops! No elements found. Consider changing the search
                        query.</span
                      >
                                        </multiselect>
                                    </div>
                                    <div class="col">
                                        <multiselect
                                                v-model="school"
                                                :options="schools"
                                                :multiple="false"
                                                group-values="schoolGroup"
                                                group-label="level"
                                                :group-select="false"
                                                placeholder="Type to search"
                                                track-by="value"
                                                label="name"
                                        >
                      <span slot="noResult"
                      >Oops! No elements found. Consider changing the search
                        query.</span
                      >
                                        </multiselect>
                                    </div>
                                    <div class="col">
                                        <button
                                                class="btn btn-success"
                                                v-on:click.prevent="saveStudent(student, $event)"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                    <template v-if="addStudentsVisible">
                        <cs-loading v-if="$wait.is('loading-students')">
                            Loading students
                        </cs-loading>
                        <div
                                class="list-group-item list-group-item-info"
                                :key="registration.member.id"
                        >
              <span class="btn-group float-right"
              ><button
                      v-on:click.prevent="addNewStudent($event)"
                      class="btn btn-primary"
              >
                  <font-awesome-icon icon="user-plus"/> Add New Student
                </button></span
              >
                        </div>
                    </template>
                </div>
                <div class="card-footer">
                    <div v-if="warningMessage !== ''"
                         class="alert alert-warning">{{ warningMessage }}
                    </div>
                    <cs-button v-if="hasStudentsToAdd"
                            type="primary"
                               icon="add-student"
                            @click="addStudentsVisible = !addStudentsVisible">
                        {{ !addStudentsVisible ? 'Add Students' : 'Hide Students' }}
                    </cs-button>
                    <cs-button v-else
                            v-on:click.prevent="addNewStudent($event)"
                            type="primary"
                               icon="add-student"
                    >Add Student
                    </cs-button>
                </div>
            </div>
        </transition>
    </div>
    <div v-else>
        <div class="alert alert-warning" v-if="loggedIn">
            <h3 class="alert-heading">Registration closed</h3>
        </div>
        <logged-out v-else :program="program"/>
    </div>
</template>

<script>
import CreateRegistrations from '@/components/registrations/CreateRegistrations'
import Gaps from '@/components/registrations/Gaps'
import LunchGaps from '@/components/registrations/LunchGaps'
import TShirt from '@/components/registrations/TShirt'
import IsNapper from '@/components/registrations/IsNapper'
import LoggedOut from '@/components/registrations/LoggedOut'
import { REGISTRATION_STATUS, UPDATE_REGISTRATION, UPDATE_REGISTRATION_MEMBER } from '@/constants/mutation-types'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { currency } from '@/utilities/stringFormatters'
import { dateString, dateTimeRange, dateTimeString, schoolYear } from '@/utilities/dateFormatters'
import { API, urlBuilder } from '@/utilities/http-common'
import { displayAbbreviation } from '@/utilities/cs-utilities'
import { addStudentToRegistration } from '@/api/registrationApi'

export default {
  name: 'Registration',
  data () {
    return {
      addStudentsVisible: false,
      value: [],
      studentId: 0,
      school: '',
      grade: [],
      loading: true,
      eligible: [],
      ineligible: [],
      warningMessage: ''
    }
  },
  components: {
    CreateRegistrations: CreateRegistrations,
    Gaps: Gaps,
    LunchGaps: LunchGaps,
    tShirt: TShirt,
    Napper: IsNapper,
    LoggedOut: LoggedOut
  },
  created () {
    if (!this.loggedIn) {
      this.changeStatus('success')
    } else {
      this.getRegistration({
        update: false,
        programSlug: this.program.slug
      })
        .then(result => {
          console.log('Registration.created', result)
        })
    }
  },

  watch: {
    member (member) {
      if (typeof member !== 'undefined' && member !== null) {
        this.loadEligibleStudents()
      }
    }
  },
  computed: {
    ...mapGetters({
      registration: 'registration',
      amountDue: 'amountDue',
      students: 'students',
      pendingAmountDue: 'pendingAmountDue',
      status: 'registrationStatus',
      program: 'program',
      year: 'year',
      member: 'registrationMember',
      loggedIn: 'loggedIn',
      schools: 'schools',
      grades: 'grades',
      isManager: 'isProgramManager'
    }),
    hasStudentsToAdd () {
      return this.filteredEligible.length + this.filteredIneligible.length > 0
    },
    filteredEligible () {
      const vm = this
      return this.eligible.filter(student => {
        if (typeof vm.registration.students !== 'undefined' && vm.registration.students !== null) {
          const found = vm.registration.students.find(registeredStudent => {
            return registeredStudent.id === student.id
          })
          if (typeof found !== 'undefined') {
            return false
          }
        }
        return true
      })
    },
    filteredIneligible () {
      const vm = this
      return this.ineligible.filter(student => {
        if (typeof vm.registration.students !== 'undefined' && vm.registration.students !== null) {
          const found = vm.registration.students.find(registeredStudent => {
            return registeredStudent.id === student.id
          })
          if (typeof found !== 'undefined') {
            return false
          }
        }
        return true
      })
    },
    balance: function () {
      return this.registration.balance
    },
    closed: function () {
      return this.registration.closed
    },
    closedDate: function () {
      return this.registration.closedDate
    },
    schoolName: function () {
      if (typeof this.school.name !== 'undefined') {
        return this.school.name
      }
      return ''
    },
    gradeName: function () {
      if (
        typeof this.grade !== 'undefined' &&
        typeof this.grade.name !== 'undefined'
      ) {
        return this.grade.name
      }
      return ''
    },
    welcome: function () {
      return window.welcome
    }
  },
  methods: {
    displayAbbreviation,
    dateTimeString,
    dateTimeRange,
    dateString,
    schoolYear,
    currency,
    ...mapMutations({
      changeStatus: REGISTRATION_STATUS,
      updateMember: UPDATE_REGISTRATION_MEMBER
    }),
    ...mapActions({ getRegistration: UPDATE_REGISTRATION }),
    openRegistration (event) {
      event.target.disabled = true
      window.location.href = '/registrations/view/' + this.registration.id
    },
    loadEligibleStudents () {
      this.$wait.start('loading-students')
      API.get(`/programs/${this.program.id}/students.json?year=${this.year}&member_id=${this.member.id}`)
        .then(response => {
          this.$wait.end('loading-students')
          const data = response.data
          this.eligible = data.eligible
          this.ineligible = data.ineligible
          this.$notify({
            group: 'enrollment',
            type: 'success',
            title: 'Students loaded',
            duration: 2000,
            speed: 1000
          })
        })
        .catch(error => {
          this.$wait.end('loading-students')
          this.$notify({
            group: 'enrollment',
            type: 'error',
            title: 'Problem loading students',
            text: error.message,
            duration: 2000,
            speed: 1000
          })
        })
    },
    hasStudents () {
      if (typeof this.registration.students === 'undefined') {
        return false
      }
      const len = Object.keys(this.registration.students).length
      return len > 0
    },
    edit (student) {
      var matches = []
      var needle = student.grade // what to look for

      this.grades.forEach(function (level) {
        matches = matches.concat(
          level.gradeGroup.filter(function (grade) {
            return grade.value === needle
          })
        )
      })

      this.grade = matches[0]
      matches = []
      needle = student.school // what to look for

      this.schools.forEach(function (level) {
        matches = matches.concat(
          level.schoolGroup.filter(function (school) {
            return school.value === needle
          })
        )
      })
      this.school = matches[0]
      this.studentId = student.id
    },
    viewStudent (student, event) {
      event.target.disabled = true
      var returnTo = window.location.pathname
      window.location.href = urlBuilder('/students/view', {
        student_id: student.id,
        return_to: returnTo
      })
    },
    addStudent (student) {
      this.$wait.start('students.add')
      this.warningMessage = ''
      addStudentToRegistration(student.id, this.registration.id, this.member.id, this.program, true)
        .then(data => {
          this.$wait.end('students.add')
          this.getRegistration({ update: true })
          console.log('Registration.addStudent', data)
          if (typeof data !== 'undefined') {
            const result = data.result
            if (
              typeof result.isError !== 'undefined' &&
              result.isError === false
            ) {
              this.$notify({
                group: 'enrollment',
                type: 'success',
                title: student.name + ' added',
                text: student.name + ' added to registration.',
                duration: 2000,
                speed: 1000
              })
            } else if (result.isError === true) {
              this.warningMessage = result.message
              this.$notify({
                group: 'enrollment',
                type: 'warning',
                title: student.name + ' added',
                text: result.message,
                duration: 2000,
                speed: 1000
              })
            }
          }
        })
        .catch(e => {
          this.$wait.end('students.add')
          console.error('Registration.addStudent', e)
        })
    },
    addNewStudent (event) {
      event.target.disabled = true
      var returnTo = window.location.pathname
      window.location.href = urlBuilder('/students/add', {
        member_id: this.registration.member.id,
        registration_id: this.registration.id,
        program: this.program.slug,
        year: this.registration.year,
        return_to: returnTo,
        next: 'add_to_registration'
      })
    },
    saveStudent (student, event) {
      event.target.disabled = true
      const body = {
        student_id: student.id,
        grade: this.grade.value,
        school: this.school.value,
        programSlug: this.program.slug
      }
      const url = 'students/edit/' + student.id

      API.patch(url, body)
        .then(response => {
          const data = response.data
          event.target.disabled = false

          if (typeof data !== 'undefined') {
            const result = data.result
            if (
              typeof result.isError !== 'undefined' &&
              result.isError === false
            ) {
              const member = data.member
              this.studentId = ''
              const updatedStudent = data.student
              this.updateMember(member)

              this.$notify({
                group: 'enrollment',
                type: 'success',
                title: updatedStudent.name + ' updated',
                text: updatedStudent.name + ' updated with your changes.',
                duration: 2000,
                speed: 1000
              })
            } else if (result.isError === true) {
              return alert(result.message)
            }
          }
        })
        .catch(e => {
          event.tartget.disabled = false
          this.$notify({
            group: 'enrollment',
            type: 'error',
            title: student.name + ' not updated',
            text: student.name + ' was not able to be updated: ' + e.message,
            duration: 2000,
            speed: 1000
          })
        })
    },
    validatedStudent (student) {
      if (typeof this.registration.validated_students !== 'undefined') {
        const found = this.registration.validated_students.find(function (
          validated
        ) {
          if (validated.id === student.id) {
            return true
          }
        })
        if (typeof found !== 'undefined') {
          return true
        }
      }
      return false
    }
  }
}
</script>

<style scoped>
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
</style>
